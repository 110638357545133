import React, { useEffect, useState } from "react";
import { Text, Link } from "basis";

import { EVENTS, ACTION, COUNTRY } from "../../../core/constants";
import { getEventDetail } from "../../../core/event-logger-helper";
import { logger } from "../../../core/logger";
import CardArt from "./CardArt";
import { generateURL, LOCATION, URL_TYPE } from "../../../services/url";
import { Loading } from "../../../components";
import Terms from "./HeadingContainer/Terms";

const HeadingContainer = ({
  au = false,
  nz = false,
  merchantId = "",
  page,
  order,
}) => {
  const country = au ? COUNTRY.AU : COUNTRY.NZ;

  const [applyURL, setApplyURL] = useState("");
  const [learnMoreURL, setLearnMoreURL] = useState("");
  const [termsURL, setTermsURL] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    setApplyURL(
      generateURL(merchantId, country, URL_TYPE.APPLY, LOCATION.ABOUT_PAGE)
    );

    setLearnMoreURL(
      generateURL(merchantId, country, URL_TYPE.LEARN_MORE, LOCATION.ABOUT_PAGE)
    );

    setTermsURL(
      generateURL(merchantId, country, 'about_terms', LOCATION.ABOUT_PAGE)
    );

    setLoading(false);
  }, [merchantId]);

  const onApplyNowClick = (e) => {
    e.preventDefault();

    const otherDetails = {
      country,
    };

    const details = getEventDetail({
      eventName: EVENTS.Apply,
      action: ACTION.Click,
      merchantId,
      page,
      otherDetails,
    });

    logger.info(details);
    window.open(applyURL, "_blank");
  };

  const onLearnMoreClick = (e) => {
    e.preventDefault();

    const otherDetails = {
      country,
    };

    const details = getEventDetail({
      eventName: EVENTS.LearnMore,
      action: ACTION.Click,
      merchantId,
      page,
      otherDetails,
    });

    logger.info(details);
    window.open(learnMoreURL, "_blank");
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Text
        textStyle="heading1"
        align="center"
        color="primary.blue.t100"
        margin="3 0">
        Enjoy now. Pay later.
      </Text>
      <Text textStyle="heading4" align="center" margin="0 0 3">
        Flexible interest free payment plans to suit your needs.
      </Text>

      <Terms order={order} au={au} nz={nz} />

      <Link
        appearance="primary-button"
        variant="green-button"
        href={applyURL}
        newTab={true}
        onClick={(e) => onApplyNowClick(e)}>
        Apply now
      </Link>
      <span>
      <Link
        href={learnMoreURL}
        newTab={true}
        testId="terms"
        onClick={(e) => {
          onLearnMoreClick(e);
        }}>
        {country === COUNTRY.AU ? `Learn more about Latitude Gem Visa` : `Learn more`}
      </Link>
      </span>
      <CardArt au={au} nz={nz} />
      <span>
        <Link href={termsURL} newTab={true} testId="terms">
          {country === COUNTRY.AU ? `Learn more about Latitude Interest Free payment plans` : `Gem Interest Free terms and conditions.`} 
        </Link>
      </span>
    </>
  );
};

export default HeadingContainer;
